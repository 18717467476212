@font-face {
  font-family: opsly;
  src: url("opsly.01bb9d7f.eot");
  src: url("opsly.01bb9d7f.eot#iefix") format("embedded-opentype"), url("opsly.25496843.woff2") format("woff2"), url("opsly.1edd543d.woff") format("woff"), url("opsly.f7b6b31d.ttf") format("truetype"), url("opsly.41c7aaca.svg#opsly") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: .2em;
  margin-right: .2em;
  font-family: opsly;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-search:before {
  content: "";
}

.icon-opsly:before {
  content: "";
}

.icon-python:before {
  content: "";
}

.icon-lambda:before {
  content: "";
}

.icon-java:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-bin:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-code-slash:before {
  content: "";
}

.icon-compute-resource:before {
  content: "";
}

.icon-buffer:before {
  content: "";
}

.icon-left-open:before {
  content: "";
}

.icon-down-open:before {
  content: "";
}

.icon-up-open:before {
  content: "";
}

.icon-down-dir:before {
  content: "";
}

.icon-up-dir:before {
  content: "";
}

.icon-left-dir:before {
  content: "";
}

.icon-right-dir:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-tags:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-alert:before {
  content: "";
}

.icon-ok:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-view-split:before {
  content: "";
}

.icon-lambda-circle:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-to-end:before {
  content: "";
}

.icon-to-end-alt:before {
  content: "";
}

.icon-to-start:before {
  content: "";
}

.icon-to-start-alt:before {
  content: "";
}

.icon-fast-fw:before {
  content: "";
}

.icon-fast-bw:before {
  content: "";
}

.icon-eject:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-attach:before {
  content: "";
}

.icon-doc:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-cancel-outline:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-clip:before {
  content: "";
}

.icon-accordion:before {
  content: "";
}

.icon-th-large:before {
  content: "";
}

.icon-th:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-minus-circled:before {
  content: "";
}

.icon-info-circled:before {
  content: "";
}

.icon-lock-1:before {
  content: "";
}

.icon-lock-open:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-edit-1:before {
  content: "";
}

.icon-trash-empty:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-floppy:before {
  content: "";
}

.icon-logs:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-1:before {
  content: "";
}

.icon-right-open:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-minus-outline:before {
  content: "";
}

.icon-plus-outline:before {
  content: "";
}

.icon-minus-1:before {
  content: "";
}

.icon-balance:before {
  content: "";
}

.icon-opsly-cloud:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-cancel-1:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-folder-opsly:before {
  content: "";
}

.icon-info-opsly:before {
  content: "";
}

.icon-panel:before {
  content: "";
}

.icon-puzzle-1:before {
  content: "";
}

.icon-radio-checked:before {
  content: "";
}

.icon-stars:before {
  content: "";
}

.icon-toolbox:before {
  content: "";
}

.icon-x-ray:before {
  content: "";
}

.icon-rack:before {
  content: "";
}

.icon-block:before {
  content: "";
}

.icon-opsly-info-filled:before {
  content: "";
}

.icon-box-outline:before {
  content: "";
}

.icon-box-filled:before {
  content: "";
}

.icon-move-1:before {
  content: "";
}

.icon-move-2:before {
  content: "";
}

.icon-social:before {
  content: "";
}

.icon-workflow:before {
  content: "";
}

.icon-diagram:before {
  content: "";
}

.icon-diagram-chart:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-clock-1:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-bomb:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-keys:before {
  content: "";
}

.icon-identity:before {
  content: "";
}

.icon-gateways:before {
  content: "";
}

.icon-dns:before {
  content: "";
}

.icon-network:before {
  content: "";
}

.icon-sql-2:before {
  content: "";
}

.icon-balancer:before {
  content: "";
}

.icon-auto-scaling:before {
  content: "";
}

.icon-aws-balancer-2:before {
  content: "";
}

.icon-full-screen:before {
  content: "";
}

.icon-exit-fullscreen:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-github-circled-alt:before {
  content: "";
}

.icon-github-circled-alt2:before {
  content: "";
}

.icon-check-empty:before {
  content: "";
}

.icon-bookmark-empty:before {
  content: "";
}

.icon-github-circled:before {
  content: "";
}

.icon-docs:before {
  content: "";
}

.icon-mail-alt:before {
  content: "";
}

.icon-comment-empty:before {
  content: "";
}

.icon-chat-empty:before {
  content: "";
}

.icon-sitemap:before {
  content: "";
}

.icon-download-cloud:before {
  content: "";
}

.icon-upload-cloud:before {
  content: "";
}

.icon-stethoscope:before {
  content: "";
}

.icon-doc-text:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-angle-right:before {
  content: "";
}

.icon-angle-up:before {
  content: "";
}

.icon-angle-down:before {
  content: "";
}

.icon-quote-left:before {
  content: "";
}

.icon-quote-right:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-folder-empty:before {
  content: "";
}

.icon-folder-open-empty:before {
  content: "";
}

.icon-terminal-1:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-star-half-alt:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-puzzle:before {
  content: "";
}

.icon-lock-open-alt:before {
  content: "";
}

.icon-minus-squared:before {
  content: "";
}

.icon-ok-squared:before {
  content: "";
}

.icon-pencil-squared:before {
  content: "";
}

.icon-link-ext-alt:before {
  content: "";
}

.icon-euro:before {
  content: "";
}

.icon-pound:before {
  content: "";
}

.icon-dollar:before {
  content: "";
}

.icon-yen:before {
  content: "";
}

.icon-bitcoin:before {
  content: "";
}

.icon-doc-inv:before {
  content: "";
}

.icon-doc-text-inv:before {
  content: "";
}

.icon-female:before {
  content: "";
}

.icon-male:before {
  content: "";
}

.icon-wheelchair:before {
  content: "";
}

.icon-child:before {
  content: "";
}

.icon-cubes:before {
  content: "";
}

.icon-recycle:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-git:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-circle-thin:before {
  content: "";
}

.icon-sliders:before {
  content: "";
}

.icon-plug:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-toggle-off:before {
  content: "";
}

.icon-toggle-on:before {
  content: "";
}

.icon-user-secret:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-times:before {
  content: "";
}

.icon-balance-scale:before {
  content: "";
}

@font-face {
  font-family: Inter;
  src: url("Inter-VariableFont_slnt,wght.4c50ba1d.ttf") format("truetype-variations");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
}

/*# sourceMappingURL=index.3f101981.css.map */
